import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
// eslint-disable-next-line import/no-extraneous-dependencies
import { render } from "storyblok-rich-text-react-renderer";

import styles from "./content.module.css";

const UI = ({
  blok,
  h1,
  restProps: { blokIndex }
}: {
  blok: any;
  h1?: boolean;
  restProps: { blokIndex?: number };
}) => {
  const { text, title, link, media } = blok;
  const Title = h1 || blokIndex === 1 ? "h1" : "h2";
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(styles.container, h1 ? styles.hero : null)}
      data-blok
    >
      <div>
        {media && media[0] && (
          <div className={styles.content__media}>
            <StoryblokComponent blok={media[0]} />
          </div>
        )}
      </div>
      <div className={styles.container__content} data-layout="page">
        <div className={clsx(styles.content__title)}>
          {title && <Title className="h2">{title}</Title>}
          {!!link?.length && (
            <div className="desktop">
              {link && <StoryblokComponent blok={link[0]} />}
            </div>
          )}
        </div>
        <div>
          <div className={styles.content__text}>
            <div>{render(text)}</div>

            {!!link?.length && (
              <div className={`mobile ${styles.linkwrap}`}>
                <StoryblokComponent blok={link[0]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UI;
