const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5515 11.4L13.0757 5.92426L13.9243 5.07574L20.8485 12L13.9243 18.9243L13.0757 18.0757L18.5515 12.6H3V11.4H18.5515Z"
        fill="black"
        style={{ fill: "var(--svg-color" }}
      />
    </svg>
  );
};

export default ArrowRight;
