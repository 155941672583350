"use client";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import BlokWrap from "@/components/atoms/BlokWrap";
import type { VideoStoryblok } from "@/types/storyblok-blok-types";

import { Video } from "@/components/atoms";
import styles from "./media.module.css";

const checkIsFull = (blok: VideoStoryblok) => {
  return blok.alignment === "full";
};

const MediaContent = ({
  blok,
  children
}: {
  children: React.ReactElement;
  blok: VideoStoryblok;
}) => {
  const full = checkIsFull(blok);

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.base,
        full && styles.full,
        blok.alignment === "center" && styles.center,
        blok.alignment === "left" && styles.left,
        blok.alignment === "right" && styles.right,
        "media-blok"
      )}
      data-blok
      data-blok-background
      data-full={full}
      data-alignment={blok.alignment}
      data-layout={full ? "full" : "page"}
      animated={full ? "custom" : "fadeY"}
    >
      <>{children}</>
    </BlokWrap>
  );
};

const VideoBlok = ({ blok }: { blok: VideoStoryblok }) => {
  return (
    <MediaContent blok={blok}>
      <Video
        url={blok?.url}
        background={false}
        controls={true}
        width="100%"
        height="auto"
        style={{
          aspectRatio: "16/10"
        }}
      />
    </MediaContent>
  );
};

export default VideoBlok;
