import type { HeroStoryblok } from "@/types/storyblok-blok-types";

import { HeroUI } from "./UI";

const Hero = ({
  blok,
  restProps
}: {
  blok: HeroStoryblok;
  restProps: { length: number; blokIndex?: number };
}) => {
  return (
    <HeroUI
      blok={blok}
      length={restProps.length ? restProps.length : 1}
      blokIndex={restProps?.blokIndex}
    />
  );
};

export default Hero;
