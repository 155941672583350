import clsx from "clsx";
import Image from "next/image";

import type { StoryBlokMedia } from "@/types/storyblok";

import styles from "./sb-image.module.css";

interface StoryBlokImageProps {
  media: StoryBlokMedia;
  vwMobile?: number;
  vwDesktop?: number;
  className?: string;
  eager?: boolean;
  sizes?: string;
}

const StoryblokImage = ({
  media,
  className,
  vwMobile = 100,
  vwDesktop = 50,
  eager,
  sizes
}: StoryBlokImageProps) => (
  <Image
    src={media.file}
    alt={media.alt}
    width={media.width ? +media.width : 0}
    height={media.height ? +media.height : 0}
    className={clsx(styles.image, className)}
    sizes={
      sizes ||
      `(max-width: 1024px) ${vwMobile}vw, (max-width: 2560px) ${vwDesktop}vw`
    }
    style={{ objectPosition: media.focus }}
    fetchPriority={eager ? "high" : undefined}
    priority={eager ? true : false}
  />
);

export default StoryblokImage;
