import { removeDeadSBSlugs } from "@/lib/storyblok/utils/getLink";
import clsx from "clsx";
import { default as LinkNext, LinkProps } from "next/link";
import styles from "./link.module.css";

interface ILinkProps extends LinkProps {
  children: React.ReactNode;
  underline?: boolean;
  className?: string;
}

const Link = ({ children, underline, ...props }: ILinkProps) => {
  const href = removeDeadSBSlugs("" + props.href);
  return (
    <LinkNext
      {...props}
      href={href ?? "#"}
      className={clsx(
        styles.link,
        underline && styles.link__underline,
        props.className
      )}
    >
      {children}
    </LinkNext>
  );
};

export default Link;
