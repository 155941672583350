const ChevronRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 5.5L15.5 12L9 18.5" style={{ stroke: "var(--svg-color)" }} />
    </svg>
  );
};

export default ChevronRight;
