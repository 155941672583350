"use client";
import ArticleCard from "@/lib/bloks/atoms/ArticleCard/ArticleCard";
import formatArticleCard from "@/lib/utils/formatArticleCards";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../content.module.css";

const ArticleSlides = ({
  articles
}: {
  articles: { full_slug: string; _uid: string }[];
}) => {
  return (
    <>
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        navigation
        modules={[Navigation, A11y]}
      >
        {articles.map((article, key) => (
          <SwiperSlide key={key} className={styles.slide}>
            <ArticleCard
              {...formatArticleCard(article)}
              key={article.full_slug}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ArticleSlides;
