import env from "@/env.mjs";

import getStoryFactory from "./utils/getStory";

const sbToken = env.NEXT_PUBLIC_STORYBLOK_TOKEN;

export const {
  getStory,
  buildGetFormattedStory,
  resolveStory,
  resolveStories,
  buildResolveAndFormatStory,
  buildResolveAndFormatStories,
  getAllPaths,
  getAllStories,
  getLinks
} = getStoryFactory(sbToken);
