import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import StoryblokComponent from "../../StoryblokComponent/StoryblokComponent";

import BlokWrap from "@/components/atoms/BlokWrap";
import bodyStyles from "@/lib/bloks/sections/Body/body.module.css";
import type { AccordionStoryblok } from "@/types/storyblok-blok-types";

import {
  Accordion as AccordionAtom,
  AccordionGroup
} from "@/components/atoms/Accordion";
import { render } from "storyblok-rich-text-react-renderer";
import styles from "./accordion.module.css";

const Accordion = ({ blok }: { blok: AccordionStoryblok }) => {
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-layout={"page"}
      className={`hero-container ${styles.root}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {blok.title && <h2>{blok.title}</h2>}
        </div>
        <div className={styles.content}>
          <AccordionGroup multiple>
            {blok.items &&
              blok.items.map((item: any, key) => (
                <div className={styles.accordionitem} key={key}>
                  <AccordionAtom title={item.title}>
                    <div
                      className={`${styles.accordionitem__body} ${bodyStyles.content__text}`}
                    >
                      {render(item.text, {
                        defaultBlokResolver: (name, props) => (
                          <StoryblokComponent
                            blok={{ component: name, ...props }}
                          />
                        )
                      })}
                    </div>
                  </AccordionAtom>
                </div>
              ))}
          </AccordionGroup>
        </div>
      </div>
    </BlokWrap>
  );
};

export default Accordion;
