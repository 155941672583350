"use client";
import styles from "../content.module.css";

import useDevice from "@/lib/hooks/useDevice";
import { ContentCardStoryblok } from "@/types/storyblok-blok-types";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ContentCard from "./Card";

const ContentSlides = ({
  content,
  title
}: {
  title?: string;
  content?: ContentCardStoryblok[];
}) => {
  const { mobile } = useDevice();
  return (
    <>
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        navigation
        modules={[Navigation, A11y]}
        className={styles.swiper}
      >
        {!mobile && title && (
          <SwiperSlide className={`${styles.slide} ${styles.titleSlide}`}>
            <div className={`desktop ${styles.content__title}`}>
              <h2>{title}</h2>
            </div>
          </SwiperSlide>
        )}
        {content?.map((item) => (
          <SwiperSlide className={styles.slide} key={item._uid}>
            <ContentCard blok={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ContentSlides;
