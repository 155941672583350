import { useLayoutEffect, useState } from "react";

import usePersistedStore from "../stateManagement/persistedState/persistedStore";

const useDevice = () => {
  const [hydrated, setHydrated] = useState(false);
  const defaultMobile = false;
  const defaultDesktop = false;
  const isMobile = usePersistedStore((state) => state.isMobile);
  const isDesktop = usePersistedStore((state) => state.isDesktop);

  const useBrowserLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : () => {};
  useBrowserLayoutEffect(() => {
    setHydrated(true);
  }, []);

  return {
    mobile: hydrated ? isMobile : defaultMobile,
    desktop: hydrated ? isDesktop : defaultDesktop,
  };
};

export default useDevice;

export type DeviceType = ReturnType<typeof useDevice>;
