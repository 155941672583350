import Link from "next/link";

import getLink from "@/lib/storyblok/utils/getLink";

import {
  MultilinkStoryblok,
  RetailerStoryblok
} from "@/types/storyblok-blok-types";
import { PropsWithChildren } from "react";
import styles from "./retailer.module.css";

const SBLink = ({
  link,
  ...props
}: PropsWithChildren<
  {
    link: MultilinkStoryblok | string | undefined;
  } & Omit<Parameters<typeof Link>[0], "href">
>) => {
  if (!link) return null;
  const href = typeof link === "string" ? link : getLink(link);
  if (!href || href === "/") return null;
  return <Link href={href} {...props} />;
};

const Retailer = ({ retailer }: { retailer: RetailerStoryblok }) => {
  const hasWebsite = "website" in retailer && !!retailer.website;
  const hasEmail = "email" in retailer && !!retailer.email;
  return (
    <tr className={styles.wrapper}>
      <td>
        <h3 className={styles.name}>{retailer.name}</h3>
      </td>
      <td>{retailer.city}</td>
      <td>
        <p>
          {retailer.address}
          <br />
          {retailer.zip} {retailer.city}
        </p>

        {retailer.mapLink && (
          <p>
            <Link href={retailer.mapLink}>{"Open in Google Maps"}</Link>
          </p>
        )}
        {hasWebsite && (
          <SBLink
            link={retailer?.website as string | MultilinkStoryblok | undefined}
            target="_blank"
          >
            {"Website"}
          </SBLink>
        )}
      </td>
      <td>
        <div className={styles.links}>
          {retailer.openingHours && (
            <div className={styles.opening}>
              {retailer.openingHours.split("\n").map((str: string, key) => (
                <p key={key}>{str}</p>
              ))}
            </div>
          )}
          {retailer.phone && <p>Contact: {retailer.phone}</p>}

          {hasEmail && (
            <SBLink
              link={retailer?.email as string | MultilinkStoryblok | undefined}
            >
              {"Email"}
            </SBLink>
          )}
        </div>
      </td>

      {/* <p>{retailer.country}</p> */}
    </tr>
  );
};

export default Retailer;
