"use client";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addToCart as addProductToCart } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import type { CartItem } from "../useSelection";
import { optimisticAddToCart } from "../utils/optimisticUpdate";

export default function useAddToCart() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);

  const addToCart = useMutation({
    mutationKey: ["addToCart"],
    mutationFn: async (variables: {
      itemId: string;
      cartItem: CartItem;
      comment?: string;
    }) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      return await addProductToCart(variables, customerToken);
    },

    onMutate: async (variables) => {
      const oldData = queryClient.getQueryData(["selection"]);
      if (!oldData) queryClient.invalidateQueries({ queryKey: ["selection"] });

      queryClient.setQueryData(["selection"], (oldCart: any) =>
        optimisticAddToCart(oldCart, {
          itemId: variables.itemId,
          cartItem: variables.cartItem
        })
      );

      return { oldData };
    },

    onError: async (error, _variables, context) => {
      return queryClient.setQueryData(["selection"], context?.oldData);
    },

    onSuccess: (response) => {
      queryClient.setQueryData(["selection"], response);
    },

    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ["selection"] });
    }
  });
  return { addToCart };
}

export type AddToCart = ReturnType<typeof useAddToCart>["addToCart"];
