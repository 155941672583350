import Close from "@/icons/Close";
import { useEngravingPrice } from "@/lib/hooks/useSelection/cart/useEngravingPrice";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./engraving.module.css";

interface IEngravingStatusProps {
  engraving?: string;
  removeEngraving?: () => void;
  className?: string;
}
export const EngravingStatus = ({
  engraving,
  removeEngraving,
  className
}: IEngravingStatusProps) => {
  const t = useTranslations();
  const engravingPrice = useEngravingPrice();

  if (!engraving) return null;

  return (
    <div className={clsx(styles.engravingStatus, className)}>
      <div>
        {t("components.engraving.text", { engraving })}

        {removeEngraving && (
          <div onClick={removeEngraving} className={styles.crossIcon}>
            <Close />
          </div>
        )}
      </div>

      <div>+{engravingPrice}</div>
    </div>
  );
};
