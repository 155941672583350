import type { SbBlokData } from "@storyblok/js";
import type { StoryblokStory } from "storyblok-generate-ts";

import env from "@/env.mjs";
import { errorLogAndThrow } from "@/lib/utils/errorLogger";
import { globalLinkMap } from "./linkMap";

const getCacheVersion = async (token: string, cache: boolean = true) => {
  const url = `https://api.storyblok.com/v2/cdn/spaces/me/?token=${token}`;
  try {
    const res = await fetch(url, {
      next: cache ? { tags: ["storyblok-cache-version"] } : { revalidate: 0 },
    }); // TODO: tag and invalidate here
    const json = await res.json();
    const { space } = json;

    if (typeof space?.version === "number") {
      return space?.version;
    }
    throw new Error("cv was not a number");
  } catch (e) {
    errorLogAndThrow(e, "Unknown problem retreiving SB cache version");
  }
  throw new Error("Cache Version was not retrieved");
};

type GetStoryProps = {
  slugOrId: string;
  folder?: string;
  preview?: boolean;
  uuid?: boolean;
};

const getStory = async <T = SbBlokData>(
  token: string,
  props: GetStoryProps,
): Promise<StoryblokStory<T>> => {
  const { slugOrId, folder, preview, uuid } = props;

  const baseUrl = "https://api.storyblok.com/v2/cdn/stories";
  const uri = folder ? `${folder}/${slugOrId}` : slugOrId;

  const tokenParam = preview
    ? `token=${env.STORYBLOK_ACCESS_TOKEN_PREVIEW}`
    : `token=${token}`;
  const cvParam = `cv=${
    preview ? await getCacheVersion(token, false) : await getCacheVersion(token)
  }`;
  const versionParam = preview ? "version=draft" : "version=published";
  const resolveLinks = "resolve_links=url";
  const resolveLinksLevel = "resolve_links_level=2";
  const findByUuid = uuid ? "find_by=uuid" : "";

  const queryParams = [
    tokenParam,
    versionParam,
    cvParam,
    resolveLinks,
    findByUuid,
    resolveLinksLevel,
  ].filter((val) => !!val);

  const url = `${baseUrl}/${uri}?${queryParams.join("&")}`;

  try {
    const res = await fetch(url, {
      next: !preview ? { tags: [`story-${uri}`] } : { revalidate: 0 },
    });

    const json = await res.json();
    const story = json.story;
    return story as StoryblokStory<T>;
  } catch (e) {
    errorLogAndThrow(e, `Unknown problem retrieving story: ${slugOrId}`);
  }
  throw new Error(`Failed to retrieve story: ${slugOrId}`);
};

const getStories = async <T = SbBlokData>(
  queryParams: string[],
): Promise<{ data: StoryblokStory<T>[]; totalStories: number }> => {
  const baseUrl = "https://api.storyblok.com/v2/cdn/stories";

  const url = `${baseUrl}?${queryParams.join("&")}`;

  try {
    const res = await fetch(url, {
      next: { revalidate: 0 },
    });

    const json = await res.json();

    const totalStories = +(res.headers.get("total") || "0");
    const stories = json.stories as StoryblokStory<T>[];
    return { data: stories, totalStories };
  } catch (e) {
    errorLogAndThrow(
      e,
      `Unknown problem retrieving stories with params ${queryParams}`,
    );
  }

  throw new Error(`Failed to retrieve stories with params ${queryParams}`);
};

const getAllStories = async <T = SbBlokData>(
  token: string,
  folder: string,
  search_term?: string,
): Promise<StoryblokStory<T>[]> => {
  const tokenParam = `token=${token}`;
  const cvParam = `cv=${await getCacheVersion(token)}`;
  const versionParam = "version=published";
  const resolveLinks = "resolve_links=url";
  const sortByDate = "sort_by=position:asc";
  const folderParam = `starts_with=${folder}`;
  const perPage = 100;
  const perPageParam = "per_page=" + perPage;
  const searchParam = search_term ? `search_term=${search_term}` : "";

  const queryParams = [
    tokenParam,
    versionParam,
    cvParam,
    searchParam,
    resolveLinks,
    sortByDate,
    perPageParam,
    folderParam,
  ].filter((val) => !!val);

  try {
    const { data, totalStories } = await getStories<T>(queryParams);

    if (totalStories - perPage < 0) {
      return data;
    }

    const totalPages = Math.ceil(totalStories / perPage);
    const pages = Array.from(Array(totalPages - 1).keys());
    const storyPages = await Promise.all(
      pages
        .map((page) => page + 2)
        .map((page) => getStories<T>([...queryParams, `page=${page}`])),
    );

    const allData = storyPages.reduce(
      (prev, curr) => [...prev, ...curr.data],
      data,
    );

    return allData;
  } catch (e) {
    errorLogAndThrow(
      e,
      `Unknown problem retrieving stories in folder: ${folder}`,
    );
  }
  throw new Error(`Failed to retrieve stories in folder: ${folder}`);
};

const getAllPaths = async <T = SbBlokData>(
  token: string,
  folder: string,
): Promise<string[]> => {
  try {
    const stories = await getAllStories<T>(token, folder);
    return stories.map((story) => story.full_slug);
  } catch (e) {
    errorLogAndThrow(
      e,
      `Unknown problem retrieving stories in folder: ${folder}`,
    );
  }
  throw new Error(`Failed to retrieve stories in folder: ${folder}`);
};

const buildGetStory = <ContentTypes = unknown>(
  token: string,
): (<T extends ContentTypes>(
  props: GetStoryProps,
) => ReturnType<typeof getStory<T>>) => {
  return async <T>(props: GetStoryProps) => await getStory<T>(token, props);
};

const buildGetAllPaths = (
  token: string,
): ((folder: string) => Promise<string[]>) => {
  return async (folder: string) => await getAllPaths(token, folder);
};

const buildGetAllStories = <ContentTypes = unknown>(
  token: string,
): (<T extends ContentTypes>(
  folder: string,
  search_term?: string,
) => ReturnType<typeof getAllStories<T>>) => {
  return async (folder: string, search_term?: string) =>
    await getAllStories(token, folder, search_term);
};

const buildGetFormattedStory =
  <T, R>(
    token: string,
    formatter: (arg0: StoryblokStory<T>) => R | Promise<R>,
  ): ((arg0: GetStoryProps) => Promise<R>) =>
  async (props: GetStoryProps): Promise<R> => {
    const data = await getStory<T>(token, props);
    const formattedData = formatter(data);

    if (formatter.constructor.name === "AsyncFunction") {
      await formattedData;
    }

    return formattedData;
  };

const resolveStory = async <ContentType extends unknown>(
  uuid: string,
  getStory: (props: GetStoryProps) => Promise<StoryblokStory<ContentType>>,
  preview = false,
) => {
  const baseProps: GetStoryProps = {
    slugOrId: uuid,
    uuid: true,
    preview: preview || undefined,
  };
  return await getStory(baseProps);
};

const resolveStories = async <
  ContentType extends unknown,
  Uuids extends readonly string[],
>(
  uuids: Uuids,
  getStory: (props: GetStoryProps) => Promise<StoryblokStory<ContentType>>,
  preview = false,
) => {
  const baseProps: GetStoryProps = {
    slugOrId: "",
    uuid: true,
    preview: preview || undefined,
  };
  const storyPromises = uuids?.map(
    async (uuid) =>
      [uuid, await getStory({ ...baseProps, slugOrId: uuid })] as const,
  );

  const stories = await Promise.all(storyPromises ? storyPromises : []);

  const keyedStories = stories.reduce(
    (acc: { [Key: string]: StoryblokStory<ContentType> }, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    },
    {},
  );

  return keyedStories as {
    [Index in Uuids[number]]: StoryblokStory<ContentType>;
  };
};

const buildResolveAndFormatStory =
  <T, R>(
    resolveStory: (arg0: string, arg1: boolean) => Promise<StoryblokStory<T>>,
    formatter: (arg0: StoryblokStory<T>) => R | Promise<R>,
  ): ((arg0: string) => Promise<R>) =>
  async (uuid: string, preview = false) => {
    const data = await resolveStory(uuid, preview);
    const formattedData = formatter(data);

    if (formatter.constructor.name === "AsyncFunction") {
      await formattedData;
    }

    return formattedData;
  };

const buildResolveAndFormatStories =
  <T, R, Uuids extends readonly string[]>(
    resolveStories: (
      arg0: Uuids,
      arg1: boolean,
    ) => Promise<{ [Key in Uuids[number]]: StoryblokStory<T> }>,
    formatter: (arg0: StoryblokStory<T>) => R | Promise<R>,
  ) =>
  async (
    uuids: Uuids,
    preview = false,
  ): Promise<{ [Key in Uuids[number]]: Awaited<R> }> => {
    const stories = await resolveStories(uuids, preview);
    const storiesArray = Object.entries<StoryblokStory<T>>(stories) as [
      Uuids[number],
      StoryblokStory<T>,
    ][];

    if (formatter.constructor.name === "AsyncFunction") {
      const formattedPromises = storiesArray.map(
        async (story) => [story[0], await formatter(story[1])] as const,
      );
      const formattedStories = await Promise.all(formattedPromises);
      const keyedStories = formattedStories.reduce(
        (acc: { [Key: string]: Awaited<R> }, cur) => {
          acc[cur[0]] = cur[1];
          return acc;
        },
        {},
      );
      return keyedStories as { [Key in Uuids[number]]: Awaited<R> };
    }

    const formattedStories = storiesArray.map(
      (story) => [story[0], formatter(story[1]) as Awaited<R>] as const,
    );
    const keyedStories = formattedStories.reduce(
      (acc: { [Key: string]: Awaited<R> }, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      },
      {},
    );
    return keyedStories as { [Key in Uuids[number]]: Awaited<R> };
  };

function buildGetLinks(token: string) {
  return async ({ preview }: { preview: boolean | undefined }) => {
    const url = new URL("https://api.storyblok.com/v2/cdn/links");
    url.searchParams.set("token", token);
    if (preview) {
      url.searchParams.set("token", env.STORYBLOK_ACCESS_TOKEN_PREVIEW);
      url.searchParams.set("version", "draft");
    } else {
      url.searchParams.set("version", "published");
    }
    url.searchParams.set("cv", await getCacheVersion(token, preview));
    url.searchParams.set("per_page", "1000");

    const response = await fetch(url, {
      next: preview ? { revalidate: 0 } : { tags: ["storyblok-cache-version"] },
    });

    if (!response.ok) {
      return;
    }

    const json = (await response.json()) as {
      links: Record<string, { id: number; uuid: string; slug: string }>;
    };

    Object.entries(json.links).forEach(([uuid, value]) => {
      globalLinkMap.set(uuid, value.slug);
    });

    return Object.fromEntries(globalLinkMap.entries());
  };
}

const getStoryFactory = <ContentTypes = unknown>(sbToken: string) => {
  const story = buildGetStory<ContentTypes>(sbToken);
  const getAllPaths = buildGetAllPaths(sbToken);
  const getAllStories = buildGetAllStories<ContentTypes>(sbToken);

  const formattedStory = <T extends ContentTypes, R>(
    formatter: (arg0: StoryblokStory<T>) => R | Promise<R>,
  ) => buildGetFormattedStory<T, R>(sbToken, formatter);

  const resolveSingle = <T extends ContentTypes>(
    uuid: string,
    preview = false,
  ) => resolveStory<T>(uuid, story, preview);

  const resolveMultiple = <
    T extends ContentTypes,
    Uuids extends readonly string[] = readonly string[],
  >(
    uuids: Uuids,
    preview = false,
  ) => resolveStories<T, Uuids>(uuids, story, preview);

  const formatResolveSingle = <T extends ContentTypes, R>(
    formatter: (arg0: StoryblokStory<T>) => Promise<R>,
  ) => buildResolveAndFormatStory<T, R>(resolveSingle<T>, formatter);

  const formatResolveMultiple = <
    T extends ContentTypes,
    R,
    Uuids extends readonly string[] = readonly string[],
  >(
    formatter: (arg0: StoryblokStory<T>) => Promise<R>,
  ) =>
    buildResolveAndFormatStories<T, R, Uuids>(
      resolveMultiple<T, Uuids>,
      formatter,
    );

  const getLinks = buildGetLinks(sbToken);

  return {
    getStory: story,
    buildGetFormattedStory: formattedStory,
    resolveStory: resolveSingle,
    resolveStories: resolveMultiple,
    buildResolveAndFormatStory: formatResolveSingle,
    buildResolveAndFormatStories: formatResolveMultiple,
    getAllPaths,
    getAllStories,
    getLinks,
  };
};

export default getStoryFactory;
