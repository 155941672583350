import env from "@/env.mjs";
import formatSelection, {
  FormattedSelection
} from "@/lib/centra/formatters/formatSelection";
import { type ICartItem } from "@/lib/centra/formatters/types";
import type { UseSelectionReturn } from "../useSelection";

export const getTotalQuantity = (
  items: UseSelectionReturn["selection"]["items"]
) =>
  items?.reduce((acc: number, item: any) => {
    return acc + item.quantity;
  }, 0);

export const getTotalPrice = (
  items: UseSelectionReturn["selection"]["items"]
): number =>
  items?.reduce((acc: number, item: any) => {
    return acc + item.quantity * (item?.priceEachAsNumber || 0);
  }, 0);

export const getGrandTotalPrice = (
  items: UseSelectionReturn["selection"]["items"],
  shippingPriceAsNumber: number = 0
) => getTotalPrice(items) + shippingPriceAsNumber;

export const formatPrice = (price: number, currency: string) => {
  // Format 1234567 into 1 234 567
  const formatted = price.toLocaleString("no");

  return `${formatted} ${currency}`;
};

const getUpdatedSelection = (
  data: FormattedSelection,
  updatedItems: Partial<ICartItem>[]
) => {
  const currency = data.selection.currency || "";
  const prices = data?.selection?.summary?.prices;
  const itemsToCalculateSummary = updatedItems as ICartItem[];

  const totalPrice = getTotalPrice(itemsToCalculateSummary) || 0;
  const grandTotalPrice = totalPrice + (prices?.shippingAsNumber || 0);

  const calculatedSummaryPrices = {
    ...prices,
    total: formatPrice(totalPrice, currency),
    grandTotal: formatPrice(grandTotalPrice, currency)
  };

  const updatedSelection = {
    ...data,
    selection: {
      ...data.selection,
      items: updatedItems
    }
  };

  const optimisticData = formatSelection(updatedSelection);

  optimisticData.selection.summary.prices = {
    ...optimisticData.selection.summary.prices,
    ...calculatedSummaryPrices
  };

  return optimisticData;
};

const optimisticQuantityUpdate = (
  data: FormattedSelection,
  variables: { newQuantity: number; line: string }
) => {
  const itemsRaw = data?.rawSelection?.items || [];

  // updated list of items (raw format)
  const updatedItems = itemsRaw.reduce<typeof itemsRaw>((items, item) => {
    if (item.line === variables.line) {
      if (variables.newQuantity === 0) return items;

      return [
        ...items,
        {
          ...item,
          quantity: variables.newQuantity
        }
      ];
    }

    return [...items, item];
  }, []);

  const optimisticData = getUpdatedSelection(data, updatedItems);

  return optimisticData;
};

export const optimisticAddToCart = (
  data: FormattedSelection,
  variables: {
    itemId: string;
    cartItem: Partial<ICartItem>;
  }
) => {
  const { itemId, cartItem } = variables;
  const itemsRaw = data?.rawSelection?.items || [];
  const isEngraving = itemId === env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID;

  const updatedItems = isEngraving
    ? // Engraving should be always separate list item
      [...itemsRaw, cartItem]
    : itemsRaw.reduce<typeof itemsRaw>((items, item) => {
        if (item.item === itemId) {
          return [
            ...items,
            {
              ...item,
              quantity: (item.quantity || 1) + 1
            }
          ];
        }

        return [...items, { ...item, quantity: 1 }];
      }, []);

  const optimisticData = getUpdatedSelection(data, updatedItems);

  return optimisticData;
};

export default optimisticQuantityUpdate;
