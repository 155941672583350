import styles from "./skeleton.module.css";

export type SkeletonProps = {
  height: string;
  width?: string;
  absolute?: boolean;
};

const UI = ({ height, width, absolute }: SkeletonProps) => {
  return (
    <div
      className={styles.skeleton}
      style={{
        animation: "skeleton-loading 1s linear infinite alternate",
        height,
        width,
        position: absolute ? "absolute" : "relative"
      }}
    ></div>
  );
};

export default UI;
