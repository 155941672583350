import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import BlokWrap from "@/components/atoms/BlokWrap";
import AspectRatioProvider from "@/components/modifiers/AspectRatioProvider";
import type { MediaGalleryStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import styles from "./media-gallery.module.css";

const MediaGallery = ({ blok }: { blok: MediaGalleryStoryblok }) => {
  const { media, gap, columns } = blok;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="custom"
      data-layout={"full"}
      className={clsx(
        styles.root,
        "media-gallery",
        gap === "true" ? styles.gap : "",
        columns === "2" ? styles.twocol : styles.threecol
      )}
    >
      <div className={styles.grid}>
        {media.map((item, index) => (
          <div
            className={clsx(styles.item, "media__gallery__item")}
            key={index}
          >
            <div
              className="media__gallery__item__content"
              style={{
                transitionDelay: `${index * 0.15}s`
              }}
            >
              <AspectRatioProvider width={1} height={1.5}>
                <StoryblokComponent blok={item} />
              </AspectRatioProvider>
            </div>
          </div>
        ))}
      </div>
    </BlokWrap>
  );
};

export default MediaGallery;
