"use client";

import useModal from "@/lib/hooks/useModal";
import clsx from "clsx";
import { FullButtonProps } from "./Button";
import styles from "./button.module.css";

const Button = ({
  color,
  outlined,
  rounded,
  className,
  shadow,
  modal,
  popover,
  full,
  ...props
}: FullButtonProps) => {
  const classes = clsx(
    styles.button,
    color ? styles[color] : null,
    rounded ? styles.rounded : null,
    outlined ? styles.outlined : null,
    shadow ? styles.shadow : null,
    full ? styles.full : null,
    className
  );
  const { toggleModal } = useModal(
    modal ? modal : "",
    () => {},
    popover ? false : true
  );
  return <button className={classes} onClick={toggleModal} {...props} />;
};
export default Button;
