import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import { useHeaderStore } from "@/lib/bloks/statics/header/MegaHeaderBloks";
import useSessionStore from "../../stateManagement/sessionState/sessionStore";
import useLockBodyScroll from "../useLockBodyScroll";

const useModal = (
  id: string,
  callback: () => void,
  lock: boolean | undefined = true
) => {
  const { setActiveSection } = useHeaderStore();
  const setModal = useSessionStore((state) => state.setModal);
  const clearModal = useSessionStore((state) => state.clearModal);
  const modalId = useSessionStore((state) => state.modalId);

  const { unlockBodyScroll, lockBodyScroll } = useLockBodyScroll(lock);

  const pathname = usePathname();
  const [prevPath, setPrevPath] = useState<string | null>(pathname ?? null);

  useEffect(() => {
    const handleRouteChange = () => {
      clearModal();
      unlockBodyScroll();
      setPrevPath(pathname);
    };
    if (prevPath !== pathname) {
      handleRouteChange();
    }
  }, [pathname, clearModal, prevPath, unlockBodyScroll]);

  const isOpen = modalId === id;

  const openModal = () => {
    setModal(id, callback);
    if (lock) {
      lockBodyScroll();
    }
  };

  const closeModal = () => {
    if (isOpen) {
      setActiveSection("");
      clearModal();
      unlockBodyScroll();
    } else {
      console.error("Modal is not open");
    }
  };
  const toggleModal = isOpen ? closeModal : openModal;

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
    currentModal: modalId
  };
};

export type IModal = ReturnType<typeof useModal>;

export default useModal;
