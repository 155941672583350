import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

import { Video } from "@/components/atoms";
import FormatStoryblokMedia from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import type { IStoryblokImage } from "@/types/storyblok";
import type {
  MediaElementStoryblok,
  MediaStoryblok
} from "@/types/storyblok-blok-types";

import styles from "./sb-media.module.css";
import StoryblokImage from "./StoryblokImage";

export interface IStoryBlokMedia extends SbBlokData {
  mobileMedia: IStoryblokImage;
  desktopMedia: IStoryblokImage;
}

export interface StoryblokMediaProps {
  blok: MediaStoryblok | undefined;
}

const MediaElement = ({
  blok,
  restProps
}: {
  blok: MediaElementStoryblok;
  restProps?: { eager?: boolean; sizes?: string; vwMobile?: number };
}) => {
  const { desktop: desktopMedia, mobile: mobileMedia } =
    FormatStoryblokMedia(blok);
  if (!blok) return <></>;
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={styles.wrapper}
      data-only-desktop={!mobileMedia?.isImage && !mobileMedia?.isVideo}
    >
      {desktopMedia && (
        <div className={styles.desktop}>
          {desktopMedia.isVideo ? (
            <Video
              url={desktopMedia.file}
              alt={desktopMedia.alt}
              id={desktopMedia._uid}
              width="100%"
              height="100%"
            />
          ) : (
            desktopMedia.isImage && (
              <StoryblokImage
                eager={restProps?.eager}
                media={desktopMedia}
                sizes={restProps?.sizes}
                key={desktopMedia.file}
              />
            )
          )}
        </div>
      )}
      {mobileMedia && (
        <div className={styles.mobile}>
          {mobileMedia.isVideo ? (
            <Video
              url={mobileMedia.file}
              alt={mobileMedia.alt}
              id={mobileMedia._uid}
            />
          ) : (
            mobileMedia.isImage && (
              <StoryblokImage
                eager={restProps?.eager}
                sizes={restProps?.sizes}
                media={mobileMedia}
                key={mobileMedia.file}
                vwMobile={restProps?.vwMobile}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MediaElement;
