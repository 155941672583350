import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

import getLink from "@/lib/storyblok/utils/getLink";
import type { MultilinkStoryblok } from "@/types/storyblok-blok-types";

import UI from "./UI";

export interface StoryblokLinkBlok extends SbBlokData {
  link: MultilinkStoryblok | undefined;
  text: string;
}

export interface StoryblokLinkProps {
  blok?: StoryblokLinkBlok;
  className?: string;
  color?:
    | "black"
    | "transparent"
    | "toggle"
    | "simple"
    | "caption"
    | "outlined"
    | "primary";
}

// Code from Roros Tweed

const StoryblokLink = ({ blok, className, color }: StoryblokLinkProps) => {
  if (!blok?.link) return null;
  const link = getLink(blok.link);
  const { text } = blok;
  return (
    <UI
      {...storyblokEditable(blok as unknown as SbBlokData)}
      text={text}
      link={link ?? ""}
      className={className}
      color={color}
    />
  );
};

export default StoryblokLink;
