import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { PageStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";

const Page = ({ blok, preview }: { blok: PageStoryblok; preview: boolean }) => {
  if (!blok.body) return <p> Missing body in blok: Page</p>;

  return (
    <div
      className={"page-blok"}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      {blok?.body?.map((blok) => {
        return (
          <StoryblokComponent blok={blok} key={blok._uid} preview={preview} />
        );
      })}
    </div>
  );
};

export default Page;
