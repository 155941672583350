import { useQuery } from "@tanstack/react-query";

import { getSelection } from "@/lib/centra/selectionFunctions";

import usePersistedStore from "../../stateManagement/persistedState/persistedStore";

export default function useSelection() {
  const setToken = usePersistedStore((state) => state.setToken);

  const selection = useQuery(
    ["selection"],
    ({ signal }) =>
      getSelection(usePersistedStore.getState().token, setToken, signal),
    {
      // Note: option to prevent excessive refetching
      // TODO: Consider setting this option as default in tanstack query configuration
      refetchOnMount: false
    }
  );

  const items = selection.data?.selection?.items ?? [];
  const summary = selection.data?.selection?.summary;
  const totals = selection.data?.selection?.totals;
  const priceList = selection?.data?.location?.pricelist;
  const market = selection?.data?.location?.market;
  const loggedIn = selection?.data?.loggedIn;
  const shippingMethods = selection?.data?.shippingMethods;
  const cart = {
    items: items,
    summary: summary,
    totals,
    priceList: priceList,
    loggedIn: loggedIn,
    shippingMethods: shippingMethods,
    PromoCodes: selection.data?.selection?.discounts
  };

  const selectionResponse = {
    data: selection.data,
    address: selection.data?.selection?.address,
    cart,
    market,
    isLoading: selection.isLoading,
    refetch: selection.refetch,
    isFetching: selection.isFetching,
    loggedIn
  };

  return selectionResponse;
}

export type UseSelectionReturn = Awaited<ReturnType<typeof getSelection>>;

export type Cart = ReturnType<typeof useSelection>["cart"];

export type CartItem = Cart["items"][number];
