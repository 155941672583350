import type { BodyStoryblok } from "@/types/storyblok-blok-types";

import UI from "./UI";

const Body = ({
  blok,
  ...props
}: {
  blok: BodyStoryblok;
  preview: boolean;
}) => {
  return <UI blok={blok} {...props} />;
};

export default Body;
