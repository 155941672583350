import { RetailersStoryblok } from "@/types/storyblok-blok-types";
import { Suspense, lazy } from "react";
import RetailersUI from "./RetailersUI";
import { fetchRetailers } from "./actions";

const ClientRetailers = lazy(() =>
  import("./ClientRetailers").then((module) => ({
    default: module.ClientRetailers
  }))
);

const ServerRetailers = async () => {
  const retailers = await fetchRetailers();
  return <RetailersUI retailers={retailers} />;
};

export const RetailersBlok = ({
  blok,
  preview
}: {
  blok: RetailersStoryblok;
  preview?: boolean;
}) => {
  if (preview) {
    return (
      <Suspense>
        <ClientRetailers blok={blok} preview={preview} />
      </Suspense>
    );
  }

  return <ServerRetailers />;
};
