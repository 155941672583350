"use client";
import Link, { type LinkProps } from "next/link";
import { type HTMLAttributes } from "react";

import clsx from "clsx";
import type { ButtonProps } from "../Button/Button";
import styles from "../Button/button.module.css";

type Props = ButtonProps & LinkProps & HTMLAttributes<HTMLAnchorElement>;

/**
 * A button that looks like a link and navigates to a specified URL when clicked.
 * Inherits all props from `ButtonProps` and `LinkProps`.
 *
 * @deprecated use `Button` and pass `href` instead
 */
const Deprecated__ButtonLink = ({
  color,
  outlined,
  rounded,
  className,
  ...props
}: Props) => {
  const classes = clsx(
    styles.button,
    color ? styles[color] : null,
    rounded ? styles.rounded : null,
    outlined ? styles.outlined : null
  );
  return <Link className={classes} {...props} />;
};

export default Deprecated__ButtonLink;
