const ChevronLeft = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5.5L8.5 12L15 18.5"
        stroke="black"
        style={{ stroke: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default ChevronLeft;
