import type { ProductListingStoryblok } from "@/types/storyblok-blok-types";

import ClientProductListingNoSSR from "./ClientProductListingNoSSR";
import ServerProductListing from "./ServerProductListing";

const ProductListing = ({
  blok,
  preview,
}: {
  blok: ProductListingStoryblok;
  preview: boolean;
}) => {
  if (preview) {
    return <ClientProductListingNoSSR blok={blok} />;
  }
  return <ServerProductListing blok={blok} />;
};

export default ProductListing;
