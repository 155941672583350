"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { resetPasswordEmail } from "@/lib/centra/selectionFunctions";

export default function useResetPasswordEmail() {
  const queryClient = useQueryClient();
  const resetPassword = useMutation(resetPasswordEmail, {
    onError: async (error: Error) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();
      return error;
    },
    onSuccess: async (data) => {
      queryClient.setQueryData(["selection"], data);
      queryClient.invalidateQueries(["selection"]);
    }
  });
  return { resetPassword };
}
