import type { PageStoryblok } from "@/types/storyblok-blok-types";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";

const Page = ({
  blok,
  preview,
  locale
}: {
  blok: PageStoryblok;
  preview: boolean;
  locale?: string;
}) => {
  if (!blok.body) return <p> Missing body in blok: Page</p>;

  return (
    <div
      className={"page-blok"}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      {blok?.body?.map((blok, index) => {
        return (
          <StoryblokComponent
            blok={blok}
            key={blok._uid}
            preview={preview}
            locale={locale}
            blokIndex={index + 1}
          />
        );
      })}
    </div>
  );
};

export default Page;
