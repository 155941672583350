import type { ProductPricesWithDiscount } from "@frend-digital/centra/types/product";

import type { Sizes } from "@/app/[locale]/(static)/product/[product]/components/ActionSection/ActionSection";

import type { CartItem } from "@/lib/hooks/useSelection/useSelection";

import type { ProductCard } from "../formatters/formatProductCards/formatProductCard";

interface CartItemPDPType {
  name?: string;
  uri?: string;
  variantName?: string;
  sizes?: {
    itemId: string;
    name: string;
  }[];
  prices?: ProductPricesWithDiscount;
  media?: {
    href: string;
    alt: string;
  }[];
}

export const convertProductToCartItem = (
  product: CartItemPDPType,
  itemId: string,
): CartItem => {
  const media = (product?.media &&
    product?.media.length > 0 &&
    product?.media[0]) || {
    href: "",
    alt: "",
  };
  return {
    name: product?.name || "",
    uri: product?.uri || "",
    series: {
      value: "",
      display: true,
    },
    shape: {
      value: "",
      display: true,
    },
    id: "",
    variant: product?.variantName || "",
    size: product?.sizes?.find((size) => size.itemId === itemId)?.name || "",
    itemId: itemId,
    price: product?.prices?.price || "",
    quantity: 1,
    sku: "",
    category: "",
    productId: "",
    discountedPrice: product?.prices?.priceBeforeDiscount || "",
    media: {
      standard: {
        href: media.href,
        alt: media.alt,
      },
      full: {
        href: media.href,
        alt: media.alt,
      },
    },
    priceAsNumber: product?.prices?.priceAsNumber || 0,
    line: "",
    engraving: undefined,
  };
};

export const convertProductCardToCartItem = (
  product: ProductCard,
  size: Sizes["selectedSize"],
  prices?: ProductPricesWithDiscount,
): CartItem => {
  return {
    name: product?.core?.name || "",
    uri: product?.core?.slug || "",
    series: {
      value: "",
      display: true,
    },
    shape: {
      value: "",
      display: true,
    },
    id: "",
    variant: product?.core?.variantName || "",
    size: size?.name || "",
    itemId: size?.itemId || "",
    price: prices?.price || "",
    sku: "",
    category: "",
    productId: "",
    quantity: 1,
    discountedPrice: prices?.priceBeforeDiscount || "",
    media: {
      standard: {
        href: product?.gallery?.image?.href || "",
        alt: product?.gallery?.image?.alt || "",
      },
      full: {
        href: product?.gallery?.image?.href || "",
        alt: product?.gallery?.image?.alt || "",
      },
    },
    priceAsNumber: prices?.priceAsNumber || 0,
    line: "",
    engraving: undefined,
  };
};
