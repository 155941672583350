import Link from "next/link";

import type { MediaElementStoryblok } from "@/types/storyblok-blok-types";

import MediaElement from "../MediaElement";
import styles from "./article-card.module.css";

export type ArticleCardType = {
  name: string;
  image?: MediaElementStoryblok;
  slug: string;
  lead?: string;
};
const ArticleCard = ({ name, image, slug, lead }: ArticleCardType) => {
  return (
    <Link href={`/${slug}`} className={styles.wrapper}>
      {image && <MediaElement blok={image} />}
      <div className={styles.title}>
        <h3 className={styles.name}>{name}</h3>
        <div className={styles.excerpt}>{lead}</div>
      </div>
    </Link>
  );
};

export default ArticleCard;
