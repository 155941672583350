"use client";
import { useState } from "react";

import BlokWrap from "@/components/atoms/BlokWrap";
import Dropdown from "@/lib/checkout/Dropdown";
import { RetailerStoryblok } from "@/types/storyblok-blok-types";
import { StoryblokStory } from "storyblok-generate-ts";
import Retailer from "./Retailer/Retailer";
import styles from "./index.module.css";

function compare(str: string, str2: string) {
  return str?.toLowerCase().includes(str2.toLowerCase());
}

const containsSearchTerm = (
  retailer: RetailerStoryblok,
  searchTerm: string
) => {
  if (!searchTerm) return true;
  const { name, address, city, country, phone } = retailer;

  switch (true) {
    case compare(name ?? "", searchTerm):
    case compare(address ?? "", searchTerm):
    case compare(city ?? "", searchTerm):
    case compare(country ?? "", searchTerm):
    case compare(phone ?? "", searchTerm):
      return true;
    default:
      return false;
  }
};

const RetailersUI = ({
  retailers
}: {
  retailers: StoryblokStory<RetailerStoryblok>[];
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [country, setCountry] = useState("all");

  const filteredRetailers = retailers
    .filter((retailer) => containsSearchTerm(retailer.content, searchTerm))
    .filter((retailer) => {
      if (country === "all") return true;
      return retailer.content?.country === country;
    });

  const countries = [
    ...new Set(
      retailers
        .map((retailer) => retailer.content?.country ?? "")
        .filter((country) => !!country)
    )
  ];

  const countryOptions = [
    { label: "All countries", value: "all" },
    ...countries.map((country) => ({ label: country, value: country }))
  ];

  return (
    <BlokWrap data-layout="page" animated="custom">
      <div className={styles["client-wrapper"]}>
        <div className={styles.head}>
          <Dropdown
            options={countryOptions}
            value={country}
            onChange={(val) => {
              if (val?.value) setCountry(val.value);
            }}
          />
          {/*
<input
          placeholder="Search..."
          className={styles.input}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
  */}
        </div>

        <div className={styles.container}>
          <table>
            <tbody>
              <tr>
                <th>{"Store"}</th>
                <th>{"City"}</th>
                <th>{"Address"}</th>
                <th>{"Opening hours"}</th>
              </tr>
              {filteredRetailers.length > 0 ? (
                filteredRetailers.map((retailer) => (
                  <Retailer retailer={retailer.content} key={retailer.id} />
                ))
              ) : (
                <tr>
                  <td>{"No retailers found"}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </BlokWrap>
  );
};

export default RetailersUI;
