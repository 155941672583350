import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import getLink from "@/lib/storyblok/utils/getLink";
import { ContentCardStoryblok } from "@/types/storyblok-blok-types";
import Link from "next/link";
import styles from "../content.module.css";

const ContentCard = ({ blok }: { blok: ContentCardStoryblok }) => {
  const href = getLink(blok.link);
  const { media, title, text } = blok;

  if (href && href !== "/") {
    return (
      <Link href={href} className={`${styles.content__card}`}>
        {media?.[0] && (
          <StoryblokComponent
            sizes="(min-width: 1024px) 30vw, 50vw"
            blok={media[0]}
          />
        )}
        {title && <h3>{title}</h3>}
        {text && <p>{text}</p>}
      </Link>
    );
  }

  return (
    <div className={`${styles.content__card}`}>
      {media?.[0] && (
        <StoryblokComponent
          sizes="(min-width: 1024px) 30vw, 50vw"
          blok={media[0]}
        />
      )}
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
    </div>
  );
};
export default ContentCard;
