import { forwardRef, Suspense } from "react";

import type { BlokParameters } from "@/lib/bloks/blok-component-types";

import ClientComponentNoSSR from "./ClientComponentNoSSR";
import ServerComponent from "./ServerComponent";

export type StoryblokComponentProps<T = BlokParameters[keyof BlokParameters]> =
  T extends BlokParameters[keyof BlokParameters]
    ? {
        blok: T;
        preview?: boolean;
        [key: string]: unknown;
      }
    : never;

const StoryblokComponent = forwardRef<HTMLElement, StoryblokComponentProps>(
  (props, ref) => {
    const { blok, preview = false, page, locale, ...restProps } = props;
    if (!blok) {
      const errorMessage = "Please provide a blok";
      console.error(errorMessage);
      return <div>{errorMessage}</div>;
    }

    if (!blok.component) {
      const errorMessage = `Component is not listed in bloks`;
      console.error(errorMessage);
      return <div>{errorMessage}</div>;
    }

    if (preview) {
      return (
        <Suspense>
          <ClientComponentNoSSR
            key={blok.component}
            blok={blok}
            preview
            restProps={restProps}
            ref={ref}
            locale={locale}
            page={page}
          />
        </Suspense>
      );
    }
    return (
      <ServerComponent
        key={blok.component}
        blok={blok}
        restProps={restProps}
        ref={ref}
        locale={locale}
        page={page}
      />
    );
  }
);

StoryblokComponent.displayName = "StoryblokComponent";

export default StoryblokComponent;
