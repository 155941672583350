import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import Hero from "@/lib/bloks/sections/Content/UI";
import type { ArticleStoryblok } from "@/types/storyblok-blok-types";
import { useTranslations } from "next-intl";
import RelatedArticles from "./components/RelatedArticles";

const Article = ({
  blok,
  page,
  locale,
  restProps
}: {
  blok: ArticleStoryblok;
  page?: string[];
  locale?: string;
  restProps?: any;
}) => {
  const t = useTranslations("page");
  return (
    <div {...storyblokEditable(blok as unknown as SbBlokData)}>
      <Hero
        blok={{ media: blok.media, title: blok.title, text: blok.lead }}
        h1
        restProps={restProps}
      />
      {blok.body?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
      <RelatedArticles
        title={t("related_articles")}
        page={page}
        locale={locale}
      />
    </div>
  );
};

export default Article;
