"use client";

import type { ProductPricesWithDiscount } from "@frend-digital/centra/types/product";
import clsx from "clsx";

import useLocation from "@/lib/hooks/useLocation";
import usePriceList from "@/lib/hooks/useSelection/cart/usePriceList";

import Skeleton from "../Skeleton";
import styles from "./price.module.css";

const Price = ({
  prices,
  className,
  skeletonHeight = "25px"
}: {
  prices?: ProductPricesWithDiscount | ProductPricesWithDiscount[];
  className?: string;
  skeletonHeight?: string;
}) => {
  const priceList = usePriceList(prices);
  const location = useLocation();

  const isDiscounted = priceList.productOnSale;

  if (isDiscounted)
    return (
      <div className={clsx(className, styles.discounted__wrapper)}>
        <span className={styles.price__before__discount}>
          <p className="sr-only">{"Price before discount."}</p>
          <span className={styles.discount__number}>
            {priceList.priceBeforeDiscountAsNumber}
          </span>
          <span> {location.currency}</span>
        </span>
        <span className={styles.price__after__discount}>
          <p className="sr-only">
            {"Price after " + priceList.discountPercent + "% discount."}
          </p>
          <span>{priceList.priceAsNumber}</span>
        </span>
      </div>
    );

  const isCurrencyNotMatching = priceList.currency !== location.currency;

  if (!priceList?.price || isCurrencyNotMatching)
    return <Skeleton height={skeletonHeight} />;

  return <span className={className}>{priceList?.price}</span>;
};

export default Price;

export const PriceSkeleton = () => <Skeleton height={"25px"} />;
