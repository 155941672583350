"use client";

import { type ButtonHTMLAttributes } from "react";

import { removeDeadSBSlugs } from "@/lib/storyblok/utils/getLink";
import clsx from "clsx";
import Link from "next/link";
import ModalButton from "./ModalButton";
import styles from "./button.module.css";
import { BUTTON_ICONS } from "./consts";

export type FullButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  color?:
    | "black"
    | "transparent"
    | "toggle"
    | "simple"
    | "caption"
    | "outlined"
    | "primary"
    | "none";
  outlined?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  full?: boolean;
  modal?: string;
  popover?: boolean;
  isLoading?: boolean;
  icon?: keyof typeof BUTTON_ICONS;
  href?: string;
  label?: string;
  underlined?: boolean;
};

const ButtonContent = ({
  children,
  isLoading,
  icon
}: Pick<FullButtonProps, "children" | "isLoading" | "icon">) => {
  const Icon = icon ? BUTTON_ICONS[icon] : () => null;

  return isLoading ? (
    <div className={styles.spinner} />
  ) : (
    <div className={styles.contentWrapper}>
      <div className={styles.label}>{children}</div>

      {icon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
    </div>
  );
};

const Button = ({
  color,
  outlined,
  rounded,
  className,
  shadow,
  modal,
  popover,
  isLoading,
  full,
  children,
  disabled,
  icon,
  href,
  label,
  underlined,
  ...props
}: FullButtonProps) => {
  const classes = clsx(
    color !== "none" ? styles.button : null,
    color ? styles[color] : null,
    rounded ? styles.rounded : null,
    outlined ? styles.outlined : null,
    shadow ? styles.shadow : null,
    full ? styles.full : null,
    underlined ? styles.underlined : null,
    className
  );

  const disabledState = isLoading || disabled;

  if (modal) {
    return (
      <ModalButton
        className={classes}
        modal={modal}
        popover={popover ? true : false}
        disabled={disabledState}
        aria-label={label}
        {...props}
      >
        <ButtonContent isLoading={isLoading} icon={icon}>
          {children}
        </ButtonContent>
      </ModalButton>
    );
  }

  if (href) {
    href = removeDeadSBSlugs(href);
    return (
      <Link aria-label={label} className={classes} href={href}>
        <ButtonContent isLoading={isLoading} icon={icon}>
          {children}
        </ButtonContent>
      </Link>
    );
  }

  return (
    <button
      aria-label={label}
      className={classes}
      {...props}
      disabled={disabledState}
    >
      <ButtonContent isLoading={isLoading} icon={icon}>
        {children}
      </ButtonContent>
    </button>
  );
};

Button.displayName = "Button";
export default Button;
