import { Button } from "@/components/atoms";
import Plus from "@/icons/Plus";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./engraving.module.css";

interface IToggleEngravingFormButtonProps {
  setIsEngravingFieldVisible: (isVisible: boolean) => void;
  isEngravingFieldVisible: boolean;
  price: string;
}

export const ToggleEngravingFormButton = ({
  setIsEngravingFieldVisible,
  isEngravingFieldVisible,
  price
}: IToggleEngravingFormButtonProps) => {
  const t = useTranslations();

  return (
    <Button
      color="transparent"
      onClick={() => setIsEngravingFieldVisible(!isEngravingFieldVisible)}
      className={styles.toggleEngravingButton}
      type="button"
    >
      <span>
        <span>
          {t("components.engraving.button.add", {
            price
          })}
        </span>

        <Plus className={clsx(isEngravingFieldVisible && styles.rotate)} />
      </span>
    </Button>
  );
};
