import ArrowRight from "@/icons/ArrowRight";
import ChevronDown from "@/icons/ChevronDown";
import ChevronLeft from "@/icons/ChevronLeft";
import ChevronRight from "@/icons/ChevronRight";
import ChevronUp from "@/icons/ChevronUp";

export const BUTTON_ICONS = {
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronUp: ChevronUp,
  arrowRight: ArrowRight
};
