import type {
  ReusableContentStoryblok,
  ReusableStoryblok
} from "@/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import { resolveStories } from "@/lib/storyblok/storyblokSetup";

export const ReusableUI = async ({
  blok
}: {
  blok: ReusableContentStoryblok;
}) => {
  const { reusable } = blok;
  const reusableStory = await resolveStories<ReusableStoryblok>([
    reusable
  ] as string[]);
  if (!reusable) {
    return null;
  }
  return (
    <>
      {typeof reusable === "string" &&
        reusableStory[reusable]?.content?.body?.map((blok) => {
          return <StoryblokComponent blok={blok} key={blok._uid} />;
        })}
    </>
  );
};
