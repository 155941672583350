import clsx from "clsx";
import { default as Image } from "next/image";
import { Suspense } from "react";

import type { ProductCard as PC } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { Price, Skeleton } from "..";
import LocalisedLink from "./LocalisedLink";
import { SuspendedProductCardActions as ProductCardActions } from "./ProductCardActions";
import styles from "./product-card.module.css";

const ProductCard = ({
  product,
  related = false,
  priority
}: {
  product: PC;
  related?: boolean;
  priority?: boolean;
}) => {
  const image = product?.gallery?.image;
  const imageAlt = product?.gallery?.secondImage;
  const slug = product?.core?.slug ?? "#";
  const name = product?.core?.shape?.value;
  const series = product?.core?.series?.value;
  const discountPercentage =
    ` ${product?.description?.price?.[0].discountPercent}%` ?? "";
  let variantSwatches;
  if (product?.description?.variants) {
    variantSwatches = product?.description?.variants.slice(0, 4);
  }

  type tag = {
    text: string;
    type: "regular" | "green" | "dark";
  };
  const tags = (() => {
    const labels: tag[] = (() => {
      if (!product?.labels) return [] as tag[];
      return product.labels.map((l) => ({
        text: l.text,
        type: (() => {
          if (l.dark === "0") return "regular";
          if (l.dark === "1") return "green";
          return "dark";
        })()
      }));
    })();

    const darkLabels = labels.filter((l) => l.type !== "regular");
    const lightLabels = labels.filter((l) => l.type === "regular");

    const newTag: tag[] = product?.core?.isNew
      ? [{ text: "New", type: "green" }]
      : [];
    const soldOutTag: tag[] =
      product?.soldOut && !product?.comingSoon
        ? [{ text: "Sold out", type: "dark" }]
        : [];
    const comingSoonTag: tag[] =
      product?.comingSoon && !product?.description?.price?.[0].productOnSale
        ? [{ text: "Coming soon", type: "green" }]
        : [];
    const onSale: tag[] =
      product?.description?.price?.[0].productOnSale && !product.soldOut
        ? [{ text: "(Sale)" + discountPercentage + " OFF", type: "green" }]
        : [];

    return [
      ...darkLabels,
      ...soldOutTag,
      ...onSale,
      ...newTag,
      ...comingSoonTag,
      ...lightLabels
    ] satisfies tag[];
  })();

  return (
    <div className={clsx(styles.card__container, related && styles.related)}>
      <LocalisedLink slug={slug}>
        <div
          className={clsx(styles.card__image, imageAlt ? styles.has_alt : null)}
        >
          {tags && (
            <div className={styles.tags}>
              {tags.map((label, key) => (
                <span
                  className={clsx(
                    styles.tag,
                    label.type === "regular" && styles.tag__regular,
                    label.type === "green" && styles.tag__green,
                    label.type === "dark" && styles.tag__dark
                  )}
                  key={clsx(label, "-", key)}
                >
                  {label.text}
                </span>
              ))}
            </div>
          )}
          {image && (
            <Image
              src={image.href}
              alt={image.alt}
              width={276}
              height={368}
              sizes={`(max-width: 1024px) 50vw, (max-width: 2560px) 20vw`}
              className={clsx(styles.product__image)}
              priority={priority}
            />
          )}
          {imageAlt && (
            <Image
              src={imageAlt.href}
              alt={imageAlt.alt}
              width={276}
              height={368}
              sizes={`(max-width: 1024px) 50vw, (max-width: 2560px) 20vw`}
              className={clsx(styles.product__image, styles.product__image_alt)}
              loading="eager"
              priority
            />
          )}
        </div>
        <div className={styles.card__description}>
          <div className={styles.product__material}>{series}</div>
          <div className={styles.product__meta}>
            <span className={styles.product__structure}>{name}</span>
            <Suspense fallback={<Skeleton height="16px" />}>
              <Price
                prices={product?.description?.price}
                className={clsx(
                  styles["product-price"],
                  "uppercase",
                  "truncate"
                )}
                skeletonHeight="12px"
              />
            </Suspense>
          </div>
          <div>
            <div className={styles.card__swatches}>
              <>
                {variantSwatches?.map((variantSwatch, key) => (
                  <div
                    key={key}
                    className={clsx(
                      styles.swatch,
                      variantSwatch?.swatch?.border === "1" && styles.border
                    )}
                    style={{ background: `${variantSwatch?.swatch?.code}` }}
                  />
                ))}

                {product?.description?.variants &&
                  product?.description?.variants.length > 4 && (
                    <>
                      <p className={styles.card__swatches_more}>
                        +{product?.description?.variants.length - 4} more
                      </p>
                    </>
                  )}
              </>
            </div>
          </div>
        </div>
      </LocalisedLink>
      {related && <ProductCardActions product={product} />}
    </div>
  );
};

export default ProductCard;
