import { ArticleCardType } from "@/lib/bloks/atoms/ArticleCard/ArticleCard";
import { render } from "storyblok-rich-text-react-renderer";

const formatArticleCard = (article: any): ArticleCardType => {
  return {
    name: article.name,
    image: article?.content?.media?.[0],
    slug: article.full_slug,
    lead: render(article.content?.lead)
  };
};

export default formatArticleCard;
