import { selectionProductAtomics } from "./atomicSetup";

const generateCartSummary = selectionProductAtomics.generateCartSummary;
const getSelectionItemName = selectionProductAtomics.getSelectionItemName;
const getSelectionItemUri = selectionProductAtomics.getSelectionItemUri;
const getSelectionItemVariantName =
  selectionProductAtomics.getSelectionItemVariantName;
const getSelectionItemSize = selectionProductAtomics.getSelectionItemSize;
const getSelectionItemLine = selectionProductAtomics.getSelectionItemLine;
const getSelectionItemId = selectionProductAtomics.getSelectionItemId;
const getSelectionItemPriceEachAsNumber =
  selectionProductAtomics.getSelectionItemPriceAsNumber;
const getSelectionItemPriceEachBeforeDiscount =
  selectionProductAtomics.getSelectionItemPriceEachBeforeDiscount;
const getSelectionItemPriceEach =
  selectionProductAtomics.getSelectionItemPriceEach;
const getSelectionItemQuantity =
  selectionProductAtomics.getSelectionItemQuantity;
const getTotalPrice = selectionProductAtomics.getTotalPrice;
const getShippingPrice = selectionProductAtomics.getShippingPrice;
const getGrandTotalPrice = selectionProductAtomics.getGrandTotalPrice;
const getTotalQuantity = selectionProductAtomics.getTotalQuantity;
const getPrices = selectionProductAtomics.getPrices;
const getDiscounts = selectionProductAtomics.getDiscounts;
const getDiscountAmount = selectionProductAtomics.getDiscountAmount;
const getFirstDiscountCode = selectionProductAtomics.getFirstDiscountCode;
const getAnyDiscount = selectionProductAtomics.getAnyDiscount;
const getDiscount = selectionProductAtomics.getDiscount;
const generselectionProductAtomicseCartSummary =
  selectionProductAtomics.generateCartSummary;
const getSelectionItemMedia = selectionProductAtomics.getSelectionItemMedia;
const getFirstImage = selectionProductAtomics.getFirstImage;
const getSelectionItemSku = selectionProductAtomics.getSelectionItemSku;
const getSelectionItemCategory =
  selectionProductAtomics.getSelectionItemCategory;
const getSelectionItemCategoryName =
  selectionProductAtomics.getSelectionItemCategoryName;
const getSelectionProductId = selectionProductAtomics.getSelectionProductId;
export {
  generateCartSummary,
  generselectionProductAtomicseCartSummary,
  getAnyDiscount,
  getDiscount,
  getDiscountAmount,
  getDiscounts,
  getFirstDiscountCode,
  getFirstImage,
  getGrandTotalPrice,
  getPrices,
  getSelectionItemCategory,
  getSelectionItemCategoryName,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemMedia,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
  getSelectionProductId,
  getShippingPrice,
  getTotalPrice,
  getTotalQuantity
};
