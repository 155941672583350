"use client";

import { useLocale } from "next-intl";
import Link from "next/link";
import { PropsWithChildren } from "react";
import styles from "./product-card.module.css";

const LocalisedLink = ({
  slug,
  children
}: PropsWithChildren<{ slug: string }>) => {
  const locale = useLocale();

  return (
    <Link
      href={locale ? `/${locale}/product/${slug}` : `/en/product/${slug}`}
      className={`product-card ${styles.card}`}
    >
      {children}
    </Link>
  );
};

export default LocalisedLink;
