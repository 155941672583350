const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8783 15.5783C13.8324 16.4651 12.4787 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 12.4028 16.5186 13.6932 15.7119 14.7149L20.0982 19.1012L19.2497 19.9497L14.8783 15.5783ZM15.8 11C15.8 13.651 13.651 15.8 11 15.8C8.34903 15.8 6.2 13.651 6.2 11C6.2 8.34903 8.34903 6.2 11 6.2C13.651 6.2 15.8 8.34903 15.8 11Z"
        fill="black"
        style={{ fill: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default Close;
