import { useEffect, useState } from "react";

function useLockBodyScroll(lockedOnDesktop: boolean | undefined = false) {
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [hydrated, setHydrated] = useState<boolean>();

  useEffect(() => {
    setHydrated(true);
  }, [lockedOnDesktop]);

  const lockBodyScroll = () => {
    if (!hydrated) return;
    setIsLocked(true);
    document.body.classList.add("scroll-locked");
    if (lockedOnDesktop) {
      document.body.classList.add("desktop-locked");
    }
  };

  const unlockBodyScroll = () => {
    if (!hydrated) return;
    setIsLocked(false);
    document.body.classList.remove("scroll-locked");
    if (lockedOnDesktop) {
      document.body.classList.remove("desktop-locked");
    }
  };

  const toggleBodyScroll = () => {
    if (!hydrated) return;
    if (isLocked) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  };

  return { isLocked, unlockBodyScroll, lockBodyScroll, toggleBodyScroll };
}

export default useLockBodyScroll;
