import { Link as LinkComponent } from "@/components/atoms";
import getLink from "@/lib/storyblok/utils/getLink";
import { LinkStoryblok } from "@/types/storyblok-blok-types";

const Link = ({ blok }: { blok: LinkStoryblok }) => {
  const text = blok.text;
  const link = getLink(blok.link);

  return <LinkComponent href={link}>{text}</LinkComponent>;
};

export default Link;
