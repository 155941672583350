import getLink from "@/lib/storyblok/utils/getLink";
import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";

import { Button } from "@/components/atoms";
import BlokWrap from "@/components/atoms/BlokWrap";
import { ButtonAtomStoryblok } from "@/types/storyblok-blok-types";

export const ButtonAtom = ({
  blok,
  restProps
}: {
  blok: ButtonAtomStoryblok;
  restProps: { style: { alignItems: string } };
}) => {
  const { link, label, variant, icon } = blok;
  const href = getLink(link);

  return (
    <BlokWrap
      style={restProps.style}
      animated=""
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      <Button
        color={variant}
        underlined={variant === "simple"}
        icon={icon}
        href={href}
      >
        {label}
      </Button>
    </BlokWrap>
  );
};
