"use client";

import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { LinkStoryblok } from "@/types/storyblok-blok-types";

import getLink from "@/lib/storyblok/utils/getLink";
import Link from "next/link";
import styles from "./header-bloks.module.css";

const MenuItem = (
  link: LinkStoryblok | { component: string; link: undefined; text: string }
) => {
  if (link.component === "divider") {
    return <div className={styles.divider} />;
  }

  const href = getLink(link.link);
  if (!href) {
    return null;
  }
  return (
    <li>
      <Link href={href} className={styles.menuButton}>
        {link.text}
        <div className={styles.underline} />
      </Link>
    </li>
  );
};

const HeaderBloks = ({ blok }: any) => {
  const { links, secondary } = blok;
  return (
    <nav
      className={styles.menu}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      <ul className={styles.menu__list}>
        {links.map((link: any) => (
          <MenuItem {...link} key={link._uid} />
        ))}
      </ul>

      <ul className={`${styles.menu__list} ${styles.secondary}`}>
        {secondary.map((link: any) => (
          <MenuItem {...link} key={link._uid} />
        ))}
      </ul>
    </nav>
  );
};

export default HeaderBloks;
