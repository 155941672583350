const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_916_611066)">
        <path
          d="M11.9706 12.8485L16.496 17.374L17.3446 16.5255L12.8191 12L17.3446 7.47452L16.496 6.62599L11.9706 11.1515L7.44507 6.62599L6.59654 7.47452L11.122 12L6.59654 16.5255L7.44507 17.374L11.9706 12.8485Z"
          style={{ fill: "var(--svg-color" }}
        />
      </g>
      <defs>
        <clipPath id="clip0_916_611066">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Close;
