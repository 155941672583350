import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";

import BlokWrap from "@/components/atoms/BlokWrap";
import type { ButtonStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "../../StoryblokComponent/StoryblokComponent";
import styles from "./button.module.css";
const Button = ({ blok }: { blok: ButtonStoryblok }) => {
  const { link, align } = blok;

  const alignment = () => {
    if (align === "center") {
      return "center";
    }
    if (align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };
  return (
    <BlokWrap
      animated=""
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={styles.wrapper}
      style={{ alignItems: alignment(), width: "100%" }}
      data-layout="page"
    >
      {link?.[0] && <StoryblokComponent blok={link[0]} />}
    </BlokWrap>
  );
};

export default Button;
