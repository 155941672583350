"use client";

import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import Link from "@/lib/bloks/atoms/Link";
import useDevice from "@/lib/hooks/useDevice";
import useModal from "@/lib/hooks/useModal";
import {
  LinkStoryblok,
  MegaHeaderStoryblok
} from "@/types/storyblok-blok-types";
import clsx from "clsx";
import { create } from "zustand";
import { MenuSectionLink, MenuSectionModal } from "./MenuSectionModal";
import styles from "./header-bloks.module.css";

export interface HeaderStore {
  activeSection: string;
  setActiveSection: (section: string) => void;
  close: () => void;
}

export const useHeaderStore = create<HeaderStore>((set) => ({
  activeSection: "",
  setActiveSection: (section) => set({ activeSection: section }),
  close: () => set({ activeSection: "" })
}));

const HeaderBloks = ({ blok }: { blok: MegaHeaderStoryblok }) => {
  const { closeModal } = useModal("global", () => {});
  const { links, secondary } = blok;
  const { activeSection, setActiveSection } = useHeaderStore();

  const { mobile } = useDevice();

  const menuWrapperStyles = clsx(
    styles.menuWrapper,
    mobile && styles.mobile,
    activeSection !== "" && styles.hasActive
  );
  const menuStyles = mobile ? styles.mobileMenu : styles.desktopMenu;
  const handleLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).closest("a")) {
      if (mobile) closeModal();
      setActiveSection("");
    }
  };

  return (
    <>
      <nav
        {...storyblokEditable(blok as unknown as SbBlokData)}
        className={menuWrapperStyles}
        onClick={handleLinkClick}
      >
        <ul className={menuStyles}>
          {links?.map((link: any) => (
            <MenuSectionLink
              link={link}
              key={link._uid}
              setActiveSection={setActiveSection}
            />
          ))}
        </ul>

        {mobile && secondary && (
          <div className={styles.secondaryLinks}>
            {secondary.map((link: LinkStoryblok) => (
              <Link blok={link} key={link._uid} />
            ))}
          </div>
        )}
        {links?.map((link: any) => (
          <MenuSectionModal
            key={link._uid}
            link={link}
            active={activeSection === link._uid}
            setActiveSection={setActiveSection}
          />
        ))}
      </nav>
    </>
  );
};

export default HeaderBloks;
