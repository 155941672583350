import type { ProductListingPageStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";

const Plp = ({ blok }: { blok: ProductListingPageStoryblok }) => {
  if (!blok) return <></>;
  return (
    <>
      {blok.Title?.map((titleBloks, index) => (
        <StoryblokComponent
          blok={titleBloks}
          key={titleBloks._uid}
          blokIndex={index + 1}
        />
      ))}
    </>
  );
};

export default Plp;
