const ChevronDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 9L12 15.5L18.5 9"
        stroke="black"
        style={{ stroke: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default ChevronDown;
