"use client";

import { useDebounce } from "@/lib/hooks/useDebounce";
import useUpdateQuantity from "@/lib/hooks/useSelection/cart/updateQuantity";
import { useEffect, useState } from "react";
import styles from "./quantity-selector.module.css";

interface IQuantitySelectorProps {
  quantity: number;
  totalProductQuantity: number;
  line: string;
}

const getDecreasedQuantity = (quantity: number) =>
  quantity === 0 ? 0 : quantity - 1;

const QuantitySelector = ({
  quantity: cartItemQuantity,
  totalProductQuantity,
  line
}: IQuantitySelectorProps) => {
  const { updateQuantity } = useUpdateQuantity();

  // Need to separate server and display quantities to correctly handle items with engraving
  const [serverQuantity, setServerQuantity] = useState(totalProductQuantity);
  const [displayQuantity, setDisplayQuantity] = useState(cartItemQuantity);

  const debouncedQuantity = useDebounce(serverQuantity, 500);

  useEffect(() => {
    setDisplayQuantity(cartItemQuantity);
  }, [cartItemQuantity, setDisplayQuantity]);

  useEffect(() => {
    setServerQuantity(totalProductQuantity);
  }, [totalProductQuantity, setServerQuantity]);

  useEffect(() => {
    if (debouncedQuantity !== totalProductQuantity) {
      updateQuantity.mutate({
        line,
        newQuantity: debouncedQuantity
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuantity]);

  const increaseQuantity = () => {
    setServerQuantity(serverQuantity + 1);
    setDisplayQuantity(displayQuantity + 1);
  };

  const decreaseQuantity = () => {
    setServerQuantity(getDecreasedQuantity(serverQuantity));
    setDisplayQuantity(getDecreasedQuantity(displayQuantity));
  };

  return (
    <span className={styles["quantity-container"]}>
      <button onClick={decreaseQuantity}>-</button>
      <p>{displayQuantity}</p>
      <button onClick={increaseQuantity}>+</button>
    </span>
  );
};

export default QuantitySelector;
