import isImageOrVideo from "@/lib/utils/isImageOrVideo";
import type { MediaElementStoryblok } from "@/types/storyblok-blok-types";
import type { AssetStoryblok } from "@/types/storyblok-content-types";

import {
  getStoryblokAlt,
  getStoryblokFile,
  getStoryblokFocus,
  getStoryblokHeight,
  getStoryblokWidth
} from "./mediaFormatters";

const getMediaType = (media: AssetStoryblok) => {
  return isImageOrVideo(media.filename);
};

const FormatStoryblokMedia = (blok: MediaElementStoryblok | undefined) => {
  if (!blok) return { desktop: undefined, mobile: undefined };
  const { desktopMedia, mobileMedia, _uid } = blok;
  if (!desktopMedia?.filename && !mobileMedia?.filename)
    return { desktop: undefined, mobile: undefined };

  const desktop = {
    file: getStoryblokFile(desktopMedia),
    alt: getStoryblokAlt(desktopMedia),
    width: getStoryblokWidth(desktopMedia),
    height: getStoryblokHeight(desktopMedia),
    focus: getStoryblokFocus(desktopMedia),
    _uid,

    ...getMediaType(desktopMedia)
  };
  const mobile = mobileMedia
    ? {
        file: getStoryblokFile(mobileMedia),
        alt: getStoryblokAlt(mobileMedia),
        _uid,
        width: getStoryblokWidth(mobileMedia),
        height: getStoryblokHeight(mobileMedia),
        focus: getStoryblokFocus(mobileMedia),
        ...getMediaType(mobileMedia)
      }
    : undefined;

  return { desktop, mobile };
};

export default FormatStoryblokMedia;
