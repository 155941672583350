"use client";
import ChevronRight from "@/icons/ChevronRight";
import useDevice from "@/lib/hooks/useDevice";
import getLink, { removeDeadSBSlugs } from "@/lib/storyblok/utils/getLink";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";

import ChevronLeft from "@/icons/ChevronLeft";
import {
  ContentCardStoryblok,
  LinkStoryblok,
  MegaMenuSectionStoryblok
} from "@/types/storyblok-blok-types";
import { default as LinkNext } from "next/link";
import { HeaderStore } from "./MegaHeaderBloks";
import styles from "./header-bloks.module.css";

interface MenuSectionLinkType {
  link: LinkStoryblok | MegaMenuSectionStoryblok;
  setActiveSection?: HeaderStore["setActiveSection"];
}
export const MenuSectionLink = ({
  link,
  setActiveSection
}: MenuSectionLinkType) => {
  const href = removeDeadSBSlugs(getLink(link.link));
  const { mobile } = useDevice();

  const handleSetActiveSection = () => setActiveSection?.(link._uid);

  const isNestedSection =
    mobile &&
    link.component === "megaMenuSection" &&
    (link?.cards?.length ||
      link?.leftColumnLinks?.length ||
      link?.rightColumnLinks?.length);

  if (setActiveSection) {
    return (
      <li
        {...(mobile
          ? { onClick: handleSetActiveSection }
          : { onMouseEnter: handleSetActiveSection })}
      >
        {isNestedSection ? (
          <>
            <span>{link.text}</span>
            <ChevronRight />
          </>
        ) : (
          <LinkNext href={href}>{link.text}</LinkNext>
        )}
      </li>
    );
  }

  return (
    <li>
      <LinkNext href={href}>{link.text}</LinkNext>
    </li>
  );
};

export const MenuCard = ({ item }: { item: ContentCardStoryblok }) => {
  const href = removeDeadSBSlugs(getLink(item.link));

  return (
    <LinkNext href={href} className={styles.content__card}>
      {item.media && item.media[0] && (
        <StoryblokComponent blok={item.media[0]} />
      )}

      {item.title && <span>{item.title}</span>}
    </LinkNext>
  );
};

const FirstSection = ({ link }: MenuSectionType) => {
  const { leftColumnLinks, leftColumnTitle } = link;

  return (
    <div data-layout="page" className={styles.desktopMenu__column}>
      {leftColumnTitle && (
        <span className={styles.desktopMenu__columnTitle}>
          {leftColumnTitle}
        </span>
      )}

      <ul>
        {leftColumnLinks?.map((link: any) => (
          <MenuSectionLink link={link} key={link._uid} />
        ))}
      </ul>
    </div>
  );
};

const SecondSection = ({ link }: MenuSectionType) => {
  const { rightColumnLinks, rightColumnTitle } = link;

  return (
    <div data-layout="page" className={styles.desktopMenu__column}>
      {rightColumnTitle && (
        <span className={styles.desktopMenu__columnTitle}>
          {rightColumnTitle}
        </span>
      )}

      <ul>
        {rightColumnLinks?.map((link: any) => (
          <MenuSectionLink link={link} key={link._uid} />
        ))}
      </ul>
    </div>
  );
};

export interface MenuSectionType {
  link: MegaMenuSectionStoryblok;
  active?: boolean;
  setActiveSection?: HeaderStore["setActiveSection"];
}

export const MenuSectionModal = ({
  link,
  active,
  setActiveSection
}: MenuSectionType) => {
  const { mobile } = useDevice();

  const { leftColumnLinks, rightColumnLinks, cards } = link;

  if (!leftColumnLinks?.length && !rightColumnLinks?.length) {
    return null;
  }

  const menuSectionModalStyles = `${active ? styles.active : ""} ${
    active ? "active" : ""
  } ${mobile && styles.mobile__mobileModalContent} ${styles.desktopMenu__section} menu-section`;

  if (mobile) {
    return (
      <div className={menuSectionModalStyles}>
        <div className={styles.desktopMenu__wrap}>
          <div
            onClick={() => setActiveSection?.("")}
            className={styles.desktopMenu__header}
            data-layout="page"
          >
            <ChevronLeft />
            <span>{link.text}</span>
          </div>

          <FirstSection link={link} />

          {cards && cards.length > 0 && (
            <div className={styles.desktopMenu__bloks}>
              {cards.map((item: any) => (
                <MenuCard item={item} key={item._uid} />
              ))}
            </div>
          )}

          <SecondSection link={link} />
        </div>
      </div>
    );
  }
  /* style={{ visibility: active ? "visible" : "hidden" }} */
  return (
    <div
      className={menuSectionModalStyles}
      style={{ visibility: active ? "visible" : "hidden" }}
    >
      <div data-layout="page" className={styles.desktopMenu__wrap}>
        <FirstSection link={link} />

        <SecondSection link={link} />

        <div className={styles.desktopMenu__bloks}>
          {cards?.map((item: any) => <MenuCard item={item} key={item._uid} />)}
        </div>
      </div>
    </div>
  );
};
