import { Deprecated__ButtonLink } from "@/components/atoms";

interface UIProps {
  text: string;
  link: string;
  className?: string;
  color?:
    | "black"
    | "transparent"
    | "toggle"
    | "simple"
    | "caption"
    | "primary"
    | "outlined";
}

const UI = ({ text, link, color }: UIProps) => {
  if (color === "simple") {
    return (
      <Deprecated__ButtonLink href={link} color={color}>
        {text}
      </Deprecated__ButtonLink>
    );
  }
  return (
    <Deprecated__ButtonLink href={link} color={color}>
      <span>
        <span>{text}</span>
      </span>
    </Deprecated__ButtonLink>
  );
};

export default UI;
