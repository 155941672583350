import type { ArticleCategoryStoryblok } from "@/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/bloks/StoryblokComponent/StoryblokComponent";
import ArticleCard from "@/lib/bloks/atoms/ArticleCard/ArticleCard";
import { getAllStories } from "@/lib/storyblok/storyblokSetup";
import formatArticleCard from "@/lib/utils/formatArticleCards";
import styles from "./article-category.module.css";

export type ArticleCardProps = {
  blok: ArticleCategoryStoryblok;
  locale?: string;
  page?: string[];
};

const ArticleCategory = async ({ blok, page, locale }: ArticleCardProps) => {
  const { hero } = blok;
  let path = locale ? locale : "";
  if (page) {
    path += "/" + page.join("/");
  }
  let articles = await getAllStories(path);
  if (articles) {
    articles = articles.filter((article) => !article.is_startpage);
  }
  const articlesCountText = articles.length === 1 ? "article" : "articles";

  return (
    <main>
      <div className="plp__header">
        {hero && <StoryblokComponent blok={hero[0]} blokIndex={1} />}
      </div>
      <div className={styles.filters} data-layout="page">
        <div
          className={styles.filters__count}
        >{`${articles.length} ${articlesCountText}`}</div>
      </div>
      <div className={styles.articles__grid} data-blok data-layout="page">
        {articles?.map((article) => (
          <ArticleCard
            {...formatArticleCard(article)}
            key={article.full_slug}
          />
        ))}
      </div>
    </main>
  );
};

export default ArticleCategory;
