import BlokWrap from "@/components/atoms/BlokWrap";
import ArticleCard from "@/lib/bloks/atoms/ArticleCard/ArticleCard";
import styles from "@/lib/bloks/layout/ArticleCategory/article-category.module.css";
import { getAllStories } from "@/lib/storyblok/storyblokSetup";
import formatArticleCard from "@/lib/utils/formatArticleCards";

export type ArticleCardProps = {
  page?: string[];
  locale?: string;
  title?: string;
};

const ArticleCategory = async ({ page, locale, title }: ArticleCardProps) => {
  let current = "";
  let path = locale ? locale : "";

  if (page && page.length > 1) {
    current = page.pop()?.toString()!;
  }
  if (page) {
    path += "/" + page.join("/");
  }
  let articles = await getAllStories(path);
  if (articles) {
    articles = articles.filter((article) => !article.is_startpage);
    if (current !== "") {
      articles = articles.filter((article) => article.slug !== current);
    }
  }
  if (articles.length > 3) {
    articles.length = 3;
  }
  /* TODO: should probobly not get more articles than necessary */

  if (!articles) {
    return null;
  }

  return (
    <BlokWrap animated="fadeY" data-layout="page">
      <h2>{title}</h2>
      <div className={styles.articles__grid}>
        {articles?.map((article) => (
          <ArticleCard
            {...formatArticleCard(article)}
            key={article.full_slug}
          />
        ))}
      </div>
    </BlokWrap>
  );
};

export default ArticleCategory;
