"use client";

import Plus from "@/icons/Plus";
import * as Acc from "@radix-ui/react-accordion";
import clsx from "clsx";

import Minus from "@/icons/Minus";
import styles from "./accordion.module.css";

/**
 * Accordion component that uses Radix UI's Accordion components.
 * @param title - The title of the accordion.
 * @param children - The content of the accordion.
 * @param styleProps - An optional object containing CSS classes for the accordion, trigger, and content.
 * @param icon - An optional icon to display next to the title.
 * @returns A React component representing an accordion.
 */

const Accordion = ({
  title,
  descriptor,
  children,
  styleProps
}: {
  title: string;
  descriptor?: string;
  children: React.ReactNode;
  styleProps?: {
    accordion?: string;
    trigger?: string;
    content?: string;
    color?: "white";
  };
}) => {
  const color = styleProps?.color;

  const accordionClasses = clsx(
    styles.accordion,
    color ? styles[color] : null,
    styleProps?.accordion
  );
  const triggerClasses = clsx(
    styles.trigger,
    color ? styles[color] : null,
    styleProps?.trigger
  );

  const contentClasses = clsx(
    styles.content,
    color ? styles[color] : null,
    styleProps?.content
  );

  return (
    <Acc.Item value={title} className={accordionClasses}>
      <Acc.AccordionTrigger className={triggerClasses}>
        {title}
        <div className={styles.decorator}>
          {descriptor && (
            <span className={styles.descriptor}>{descriptor}</span>
          )}
          {/* Note: only icon is displayed at the time depending on state */}
          <Plus />
          <Minus />
        </div>
      </Acc.AccordionTrigger>

      <Acc.AccordionContent className={contentClasses}>
        {children}
      </Acc.AccordionContent>
    </Acc.Item>
  );
};

export default Accordion;
