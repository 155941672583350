import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

// eslint-disable-next-line import/no-extraneous-dependencies

import styles from "./index.module.css";

const UI = ({ blok }: { blok: any }) => {
  const { klavioId } = blok;
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-blok
      data-layout="page"
    >
      <div className={clsx(styles.container, styles[blok?.alignment])}>
        <div className={`klaviyo-form-${klavioId}`}></div>
      </div>
    </div>
  );
};

export default UI;
