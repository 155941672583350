"use client";

import useStore from "@/lib/stateManagement/persistedState/persistedStore";
import useModal from "../../useModal";
import useSelection from "../useSelection";
import useRemoveFromCart from "./removeFromCart";
import useRemovePromo from "./removePromo";
import useUpdateQuantity from "./updateQuantity";
import { useAddPromo } from "./useAddPromo";
import useAddToCart from "./useAddToCart";

const useCart = () => {
  const local_token = useStore((state) => state.token);
  const { cart, isLoading, data, refetch, isFetching } = useSelection();

  const modal = useModal("cart", () => {}, true);
  const { addToCart } = useAddToCart();
  const { addPromo } = useAddPromo();
  const { removePromo } = useRemovePromo();
  const { removeItems } = useRemoveFromCart();
  const { updateQuantity } = useUpdateQuantity();

  const changeShippingMethod = async (shippingMethod: string) => {
    if (!local_token) {
      console.error("Missing customer token key");
      throw new Error("Missing customer token key");
    }
    const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
    if (window.CentraCheckout) window.CentraCheckout.suspend();
    const res = await fetch(`${apiUrl}/shipping-methods/${shippingMethod}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "API-Token": local_token || "",
      },
    });
    const data = await res.json();
    if (window.CentraCheckout) window.CentraCheckout.resume();
    refetch();
    return data;
  };

  if (!data?.selection?.shippingMethod && cart?.shippingMethods?.length) {
    changeShippingMethod(cart?.shippingMethods[0].shippingMethod);
  }

  return {
    cart: cart,
    // To be removed?
    items: cart?.items,
    isLoading,
    isFetching,
    addItem: addToCart,
    addPromo,
    removePromo,
    removeItems,
    updateQuantity,
    modal,
    summary: cart?.summary,
    shippingMethods: cart?.shippingMethods,
    currentShippingMethod: data?.selection?.shippingMethod,
    changeShippingMethod,
    currency: data?.selection?.currency,
  };
};

export type CartHook = ReturnType<typeof useCart>;

export default useCart;
