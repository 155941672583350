const Plus = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.4 12.6V19H12.6V12.6H19V11.4H12.6V5H11.4V11.4H5V12.6H11.4Z"
        fill="black"
        style={{ fill: "var(--svg-color" }}
      />
    </svg>
  );
};

export default Plus;
