"use client";
import * as Acc from "@radix-ui/react-accordion";
import { forwardRef } from "react";

/**
 * A component that groups multiple `AccordionItem` components together.
 * It can be configured to allow multiple items to be open at the same time or only one at a time.
 * @param children The `AccordionItem` components to be grouped together.
 * @param className An optional CSS class name to apply to the root element of the component.
 * @param multiple An optional string value that determines whether multiple items can be open at the same time.
 * If set to "multiple", multiple items can be open at the same time. Otherwise, only one item can be open at a time.
 * @param props Any additional props to be spread to the root element of the component.
 * @param forwardedRef A ref object that can be used to access the underlying DOM element.
 * @returns A React component representing the `AccordionGroup`.
 */

const AccordionGroup = forwardRef(
  (
    { children, className, multiple, ...props }: ForwardRefProps,
    forwardedRef: DivForwardRef
  ) => {
    return (
      <Acc.Root
        className={className}
        {...props}
        ref={forwardedRef}
        type={multiple ? "multiple" : "single"}
      >
        {children}
      </Acc.Root>
    );
  }
);

AccordionGroup.displayName = "AccordionGroup";

export default AccordionGroup;

export type ButtonForwardRef =
  | ((instance: HTMLButtonElement | null) => void)
  | React.MutableRefObject<HTMLButtonElement | null>
  | null
  | undefined;

export type DivForwardRef =
  | ((instance: HTMLDivElement | null) => void)
  | React.MutableRefObject<HTMLDivElement | null>
  | null
  | undefined;

export type ForwardRefProps = {
  children: React.ReactNode;
  className: string;
  name?: string;
  [key: string]: any;
};
