"use client";

import { ICartItem } from "@/lib/centra/formatters/types";
import useRemoveFromCart from "@/lib/hooks/useSelection/cart/removeFromCart";
import { useAddEngraving } from "@/lib/hooks/useSelection/cart/useEngraving";
import { useEngravingPrice } from "@/lib/hooks/useSelection/cart/useEngravingPrice";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { EngravingForm } from "./EngravingForm";
import { EngravingStatus } from "./EngravingStatus";
import { ToggleEngravingFormButton } from "./ToggleEngravingFormButton";
import styles from "./engraving.module.css";

export interface IEngravingProps {
  item: ICartItem;
  isFinalized?: boolean;
}

export const CartItemEngraving = ({ item, isFinalized }: IEngravingProps) => {
  const t = useTranslations();
  const { removeItems } = useRemoveFromCart();
  const { addEngraving } = useAddEngraving();
  const [isEngravingFormVisible, setIsEngravingFormVisible] = useState(false);

  const engravingText = item?.engraving?.txt;
  const engravingPrice = useEngravingPrice();

  const handleRemoveEngraving = () => {
    if (item?.engraving?.engravingId) {
      removeItems.mutate([
        {
          line: item?.engraving?.engravingId,
          numberOfItemsToRemove: 1
        }
      ]);
    }
  };

  return (
    <div className={styles.engraving}>
      {!isFinalized && (
        <>
          {!engravingText && (
            <ToggleEngravingFormButton
              setIsEngravingFieldVisible={setIsEngravingFormVisible}
              isEngravingFieldVisible={isEngravingFormVisible}
              price={engravingPrice}
            />
          )}

          {isEngravingFormVisible && (
            <EngravingForm
              handleAddEngraving={({ engraving }) =>
                addEngraving({
                  itemData: item,
                  txt: engraving,
                  onSuccess: () => setIsEngravingFormVisible(false)
                })
              }
              buttonLabel={t("common.add")}
            />
          )}
        </>
      )}

      {engravingText && (
        <EngravingStatus
          engraving={item?.engraving?.txt}
          removeEngraving={isFinalized ? undefined : handleRemoveEngraving}
        />
      )}
    </div>
  );
};
