import { useTranslations } from "next-intl";
import * as yup from "yup";

export const useValidation = () => {
  const t = useTranslations();

  const validationMessages = {
    mixed: {
      default: t("common.validation.default"),
      required: t("common.validation.required")
    },

    string: {
      email: t("common.validation.default"),
      max: ({ max }: { max: number }) => t("common.validation.max", { max })
    }
  };

  yup.setLocale(validationMessages);

  const validation = yup;

  return validation;
};
